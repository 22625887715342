import React from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"

const Nav = ({ variants, className, initial, animate, exit, ...props }) => {
  return (
    <motion.div
      className={`header-bar ${className}`}
      variants={variants}
      initial={initial}
      animate={animate}
      exit={exit}
    >
      <h2>Webinars NEST</h2>
      <nav variants={variants} initial={initial} animate={animate} exit={exit}>
        <div>
          <ul>
            <li>
              <Link activeClassName="is-active" to="/">
                Próximos eventos
              </Link>
            </li>
            <li>
              <Link activeClassName="is-active" to="/past">
                Eventos passados
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </motion.div>
  )
}

export default Nav
