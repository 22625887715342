import React from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"

import Nav from "./nav"
import Hero from "./hero"
import "../styles/main.css"

const duration = 0.35

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: duration,
    },
  },
}

const Layout = ({ children, location }) => {
  return (
    <>
      <div className="min-h-screen flex flex-wrap bg-main-neutral">
        <Hero
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        />

        <div className="w-full lg:w-2/3 bg-gray-200">
          <Nav
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          />

          <div className="p-8">
            <AnimatePresence>
              <motion.main
                key={location.pathname}
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
              >
                {children}
              </motion.main>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
