import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import Logo from "../images/nest-tourism-innovation-center-portugallogo.gif"

const Hero = ({
  variants,
  className,
  initial,
  animate,
  exit,
  data,
  ...props
}) => {
  return (
    <motion.div
      className={`hero ${className}`}
      variants={variants}
      initial={initial}
      animate={animate}
      exit={exit}
    >
      <div>
        <div>
          <Link to="/">
            <h1>
              <img src={Logo} alt="Nest - Tourism Innovation Center Portugal" />
            </h1>
          </Link>

          <h2>Tourism NOW!</h2>

          <p>
            Um programa para cuidar do presente e preparar o futuro. Os nossos
            webinars respondem às necessidades atuais de conhecimento e
            inspiração para empresas, PMEs e startups que querem inovar no
            turismo.
          </p>

          <p>Bem vind@ aos Webinars NEST</p>
        </div>
      </div>
    </motion.div>
  )
}

// export data = useStaticQuery(graphql`
// query logoQuery {
//   file(relativePath: { eq: "images/corgi.jpg" }) {
//     childImageSharp {
//       # Specify the image processing specifications right in the query.
//       fluid {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }
// `)

export default Hero
